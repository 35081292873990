import { openSans } from "app/layout";
export const fontSize = 14;
export const typography = {
  fontSize,
  htmlFontSize: 16,
  fontFamily: openSans.style.fontFamily,
  body1: {
    fontSize
  },
  body2: {
    fontSize
  }
};