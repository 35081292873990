export const resources = {
  en: {
    translation: {
      HOT: "HOT",
      "Free Express Shipping": "Free Express Shipping"
    }
  },
  es: {
    translation: {
      HOT: "HEISS",
      "Free Express Shipping": "Gratis Expresverzending"
    }
  }
};